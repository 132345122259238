/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:f333aa98-57f6-4da6-a37f-ab8d5606fe04",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_elgDMuAGC",
    "aws_user_pools_web_client_id": "7k6cb190h6mnpci8b72gp0ehud",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "gieserviceapi",
            "endpoint": "https://pc9krhr22d.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        },
        {
            "name": "gieserviceloggerapi",
            "endpoint": "https://qwscdc5be3.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "gieservicereact4a161e5be5c04ae1908a77d50f8f6a5b130657-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
