import React, {useState, useEffect} from 'react';
import Amplify, {API, Auth} from 'aws-amplify' ;
import config from './aws-exports';
import {AmplifySignOut, AmplifySignIn, AmplifyAuthenticator } from '@aws-amplify/ui-react';
import ReactPaginate from "react-paginate";
import "./App.css";
import logo from './loading-icon.gif';
import telstra from './telstra2.jpg';
import telstra1 from './telstra3.jpg';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';


Amplify.configure(config);

function App() {
	
	let [offset,setOffset] = useState(0);
	let [tableData,setTableData] = useState([]);
	let [orgTableData,setOrgTableData] = useState([]);
	let perPage = 10;
	let [currentPage,setCurrentPage] = useState(0);
	let [pageCount,setPageCount] = useState(0);

	let [showResults,setShowResults] = useState(false);
	let [showPagination,setShowPagination] = useState(false);
	let [showLogo,setShowLogo] = useState(true);
	
	let [showAddress,setShowAddress] = useState(false);
	let [address,setAddress] = useState([]);
	
	let [authState, setAuthState] = useState('');
    let [user, setUser] = useState('');

	let [errorMessage, setErrorMessage] = useState('');
	let [textColor, setTextColor] = useState('red');

	let [hideBtnDiv,setHideBtnDiv] = useState(false);
	
	let termNodalGroup='termcellNodal';
	let termGroup='termcell';
  	
	useEffect( () => {
	
		let authFlag = true;
		onAuthUIStateChange((nextAuthState, authData) => {
			setAuthState(nextAuthState);
            setUser(authData);
			if(authFlag && nextAuthState === 'signedin')
			{
				authFlag = false;
				API.post('gieserviceloggerapi','/gieservicelogger',{body:{"loggerText":"info Signedin"}}).then(logResult => {}).catch(err=>{});
				apiCall();
			}
			else if(nextAuthState === 'signedout'){
				window.location.reload();
			}			
		});						
	},[]);
	 
	function apiCall(){
		Auth.currentSession().then(data =>{
			try{
				let lambdaResData = [];
				let groupName='';
				if(data.idToken.payload.["cognito:groups"] !== undefined){
					groupName = data.idToken.payload.["cognito:groups"].toString();
				}				
				if(	groupName === termGroup || groupName === termNodalGroup )
				{
					API.get('gieserviceapi','/gieservice').then(lambdaResult => {
						if(lambdaResult !== 'error' && lambdaResult != null){
							lambdaResData = lambdaResult.userLocationData[0];
							if(lambdaResData.length > 0){
								let slice = lambdaResData.slice(offset,offset+perPage);											
								if(lambdaResData.length >10){
										setShowPagination(true);
								}							
								setPageCount(Math.ceil(lambdaResData.length /perPage));
								setOrgTableData(lambdaResult.userLocationData[0]);
								setTableData(slice);								
								setShowResults(true);
								setShowLogo(false);
								if(groupName === termNodalGroup)
									setHideBtnDiv(true);
								else
									setHideBtnDiv(false);
							}
							else{
								setErrorMessage('No Data Available');
								setTextColor('red');
								setShowLogo(false);
								API.post('gieserviceloggerapi','/gieservicelogger',{body:{"loggerText":"error Empty result from API"}}).then(logResult => {}).catch(err => {});					
							}
						}
						else{
							setErrorMessage("Service is experiencing technical difficulties, Please try again.");
							setTextColor('black');
							setShowLogo(false);	
							API.post('gieserviceloggerapi','/gieservicelogger',{body:{"loggerText":"error Error in lambda function"}}).then(logResult => {}).catch(err => {});
						}
					}).catch(err => {
						setErrorMessage("Service is experiencing technical difficulties, Please try again.");
						setTextColor('black');
						setShowLogo(false);
						API.post('gieserviceloggerapi','/gieservicelogger',{body:{"loggerText":"error "+err}}).then(logResult => {}).catch(err=>{});
					});
				}
				else{
					setErrorMessage("No Data Available");
					setTextColor('red');
					setShowLogo(false);
					API.post('gieserviceloggerapi','/gieservicelogger',{body:{"loggerText":"error Invalid User Group Details"}}).then(logResult => {}).catch(err=>{});
				}
			}catch(err){
				console.log(err);
			}
		});
		
		 
	}
	
	const handlePageClick = (e) => {
        let selectedPage = e.selected;
        offset = selectedPage * perPage;		
        setCurrentPage(selectedPage);
        setOffset(offset);
        loadMoreData()
    };
		
	const loadMoreData = (e) => {
		let data = orgTableData;
		let slice = data.slice(offset, offset + perPage);
		setPageCount(Math.ceil(data.length / perPage));
		setTableData(slice);	
    }
		
	const handleAddressBtn = (addres) => {
		setErrorMessage('');
		setAddress(addres);		
		setShowAddress(true);
		setShowResults(false);
	};
	
	const handleBackClick = () => {
		setShowAddress(false);
		setShowResults(true);
		document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );

	};
	
	let serviceIdList = [];
	const handleCheckboxChange = (serviceId,hiddenVal,e) => {
		if(e.target.checked)
		{
			serviceIdList.push({
				serviceid:serviceId,
				hiddenval:hiddenVal
			});
			//serviceIdList.push(serviceId);
		}
		else{
			//let indexVal = serviceIdList.indexOf(serviceId);
			let indexVal = serviceIdList.findIndex(val => val.serviceid === serviceId);			
			if (indexVal > -1) {
				serviceIdList.splice(indexVal, 1);
			}
		}
	};
	
	const clearAllDiv = () => {
		setShowResults(false);
		setShowLogo(true);
	};

	
	const handleHide = (e) =>{
		let btnVal=e.target.value;
		try{
			if(serviceIdList.length >0){
				let isHidevalid = serviceIdList.filter((obj1) => obj1.hiddenval === "Yes").length === 0 && btnVal === 'Hide';
				let isUnHideValid =serviceIdList.filter((obj2) => obj2.hiddenval === '').length === 0 && btnVal === 'Unhide' ;
	
				if(isHidevalid  ||  isUnHideValid){
					clearAllDiv();
					API.post('gieserviceapi','/gieservice',{body: {serviceIdList,btnVal}}).then(res => {
					window.location.reload();
					})
					.catch(err => {
						setErrorMessage("Hide and Unhide Service is experiencing technical difficulties, Please try again.");
						setTextColor('red');
						setShowLogo(false);
					});
				}
				else{
					setErrorMessage("Please select record properly");
					setTextColor('red');
					document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
				}				
			}
			else{
				setErrorMessage("Please select atleast record");
				setTextColor('red');
				document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
			}
		}catch(err){
		}
	};
	
	return authState === AuthState.SignedIn && user ? (
	
		<div className="App">		
			<div className="container-fluid container-fluid--auth">				
				<div className="row">
					<div className="col-md-4" style={{float:"right"}}>
						<img  src={telstra} alt='Logo'  className='logoStyle'/>
					</div>
					<div className="col-md-4 offset-md-4" >
						<h2 className="headerText">Telstra Service Details</h2>
					</div>
				</div>
			</div>

			<AmplifySignOut style={{float:"right",position:"relative"}}/>
					
			<div>&nbsp;{errorMessage && (<p className="error" style={{color:textColor}}> {errorMessage} </p>)}
			</div>

			<div><h3 style={{display:(showResults? 'block':'none')}} className="pStyle">GIE Service Details</h3>
			</div>
			<div style={{display:(showResults? 'block':'none')}}>	
				<div  className="tableDiv">
				<table className="tableStyle">
					<thead className="theaderStyle">
						<tr>
							{(user.signInUserSession.idToken.payload.["cognito:groups"] !== undefined && user.signInUserSession.idToken.payload.["cognito:groups"].toString() === termNodalGroup) &&
							<th></th>}
							<th>Customer Name</th>
							<th>Telstra Service Id</th>
							<th>Supplier Service ID</th>
							<th>POP</th>
							<th>LSA</th>						
							<th>Typeof Customer</th>
							<th>Transport Media</th>
							<th>Last Mile Access Provider</th>
							<th>Availability</th>
							{(user.signInUserSession.idToken.payload.["cognito:groups"] !== undefined && user.signInUserSession.idToken.payload.["cognito:groups"].toString() === termNodalGroup) &&
							<th>SOF</th>}							

						</tr>
					</thead>
					<tbody>
					
					{tableData.map((tData)=>(
						<tr key = {tData.[" S.  No"]} style={{backgroundColor:(tData.Hidden  === "Yes" ? 'lightgrey':'none')}}>
						{(user.signInUserSession.idToken.payload.["cognito:groups"] !== undefined && user.signInUserSession.idToken.payload.["cognito:groups"].toString() === termNodalGroup) &&
						<td ><input type="checkbox" onChange={handleCheckboxChange.bind(this,tData.["Telstra Service ID"],tData.Hidden)}/></td>}
						<td>{tData.Name}</td>					
						<td><button className='btnStyle' onClick={handleAddressBtn.bind(this, tData)}>{tData.["Telstra Service ID"]}</button></td>					
						<td>{tData.["Supplier Service ID"]}</td>
						<td>{tData.POP}</td>
						<td>{tData.LSA}</td>
						<td>{tData.["Type of Customer"]}</td>
						<td>{tData.["Transport Media"]}</td>
						<td>{tData.["Last Mile Access Provider"]}</td>
						
						<td><a style={{display:(tData.MRTG !== "" ? 'inline':'none')}} href={tData.MRTG} rel="noopener noreferrer" target="_blank">MRTG</a>&nbsp;
						<a style={{display:(tData.UDP  !== "" ? 'inline':'none')}} href={tData.UDP} rel="noopener noreferrer" target="_blank">UDP</a>
						</td>
						{(user.signInUserSession.idToken.payload.["cognito:groups"] !== undefined && user.signInUserSession.idToken.payload.["cognito:groups"].toString() === termNodalGroup) && <td ><a style={{display:(tData.SOF !== "" ? 'inline':'none')}} href={tData.SOF} rel="noopener noreferrer" target="_blank">SOF</a></td>}
						
						</tr>
					))}
					</tbody>
				</table></div>
				<div className="pStyle"><p style={{textAlign:"left"}}>Note: Click on <em style={{color:"blue"}}>Telstra Service Id</em> for more details </p></div>
				
				<div style={{display:(hideBtnDiv ? 'block':'none')}} className="pStyle">
					<p>Note: Highlighted Rows are Hidden Records.Click on <em style={{color:"blue"}}>Hide and Unhide </em>buttons for Hiding and Unhiding data from Term Cell Users</p>
					<button value="Hide" onClick={handleHide} >Hide</button>&nbsp;
					<button value="Unhide" onClick={handleHide} >Unhide</button>					
                </div>
				</div>
				
				<div style={{display:(showPagination && showResults? 'block':'none')}}>		
					<ReactPaginate
					previousLabel={"Prev"}
					nextLabel={"Next"}
					breakLabel={"..."}
					breakClassName={"break-me"}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					pageCount={pageCount}
					onPageChange={handlePageClick}
					containerClassName={"pagination"}
					subContainerClassName={"pages pagination"}
					activeClassName={"active"}/>
				</div>	


				<div className="addressDiv" style={{display:(showAddress ? 'block':'none')}}>
					<h3 >Details of {address.["Telstra Service ID"]}</h3>
					<div>
					<table style={{	border: "1px solid black", width:"95%"}}>
						<thead className="">
							<tr>	
								<th>Bandwidth</th>
								<th>LAN IP</th>
								<th>WAN IP</th>
								<th>Address</th>
								<th>City/Town/Village</th>
								<th>District</th>
								<th>ActivationDate</th>		
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{address.Bandwidth}</td>
								<td>{address.["LAN IP"]}</td>
								<td>{address.["WAN IP"]}</td>
								<td>{address.Address}</td>
								<td>{address.["City/Town/Village"]}</td>
								<td>{address.District}</td>
								<td>{address.["Activation Date"]}</td>

							</tr>
						</tbody>
					</table>
					</div>
					<div><br /><button onClick={handleBackClick}>Back</button></div>
				</div>

				<img src={logo} alt="Logo" className="imgStyle" style={{display:(showLogo? 'block':'none')}} />		
				
		</div>
	):(
		<div className="container-fluid container-fluid--auth">	
		  <div className="row">
			<div className="col-md-8">
			  
			  <div className="row">
				<div className="col-12 py-8" style={{float:"right"}}>				
				  <img  src={telstra1} alt='Logo'  className='logoStyle'/>
				</div>
			  </div>
			  
			  <div className="row">
				<div className="col-md-8">
				  <AmplifyAuthenticator>
					<AmplifySignIn slot="sign-in" headerText="Telstra Service Details" formFields={[ { type: "username" },{ type: "password", hint: '' } ]} hideSignUp />
				  </AmplifyAuthenticator>
				</div>
			  </div>
			  
			</div>			
		  </div>
		</div>		
	);
}

export default App
